import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { CSSTransition } from 'react-transition-group';
import styled from "styled-components"
import { connect } from 'react-redux'
import { toggleWhiteSidebar,toggleNewsletter } from "../../state/app"
import Footer from "../footer"
import Cookies from '../Cookies'
import FormNewsletter from '../FormNewsletter'
import IconsContactNewsletter from '../IconsContactNewsletter'
import { InView } from 'react-intersection-observer'

import favicon from '../../images/Favicon-01.png'
import Helmet from 'react-helmet'

import "./layout.css"

const Layout = ({ children, fourOhFour, data, openNewsletter, hideForm, dispatch, whiteMobileNavbar, location }) => {

    if(location.hash.includes('#newsletter')){
        dispatch(toggleNewsletter(true))
    }
    
    
    return (
        <StyledLayout id="top">
            <Helmet>
                <link rel="icon" href={favicon} />
            </Helmet>

            {/* CONTACT + NEWSLETTER ICONS*/}
            <IconsContactNewsletter
             
                openNewsletter={openNewsletter}
            />

            {/* NEWSLETTER POPUP */}
            <CSSTransition in={openNewsletter} timeout={250} classNames="form-animation" unmountOnExit>
                <FormNewsletter location={location}
                    data={data.newsletter}
                />
            </CSSTransition>

            <main>{children}</main>

            {!fourOhFour &&
                /* CHANGE SIDE BAR COLOR TO WHITE */
                hideForm ?
                <Footer
                    form={data.form}
                    hideForm={hideForm}
                    location={location}
                    msgContent={data.msgMarkPhone}
                />
                :
                <InView as="div" threshold={0.5} onChange={inView => dispatch(toggleWhiteSidebar(inView))}>
                    <Footer
                        form={data.form}
                        hideForm={hideForm}
                        location={location}
                        msgContent={data.msgMarkPhone}
                    />
                </InView>
            }
        </StyledLayout>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default connect(state => ({
    openNewsletter: state.app.openNewsletter,
    news: state.app.news,
    whiteSidebar: state.app.whiteSidebar
}), null)(Layout)

const StyledLayout = styled.main`
    // ANIMAÇÕES
    .form-animation-enter {
        opacity: 0;
    }
    .form-animation-enter-active {
        opacity: 1;
        transition: opacity 250ms;
    }
    .form-animation-exit {
        opacity: 1;
    }
    .form-animation-exit-active {
        opacity: 0;
        transition: opacity 250ms;
    }
`
