import { Link } from "gatsby"
import React from "react"
import { useI18next } from 'gatsby-plugin-react-i18next'
import { Image } from './Images'
import { media } from './Styles'

import styled from "styled-components"
import Form from "./FormCasa"

import logo from "../images/misc/Logo-Silver.svg"
import face from "../images/homeMenu/facebook-logo.svg"
import insta from "../images/homeMenu/instagram-logo.svg"
import tube from "../images/homeMenu/youtube-symbol.svg"
import linked from "../images/homeMenu/linked.svg"

import { handleCall } from "../utils/handleClick"

const Footer = ({ form, hideForm, location, msgContent }) => {

    const { language } = useI18next()

    var en = location.pathname.split('/')[1] === 'en'

    return (
        <>
            {!hideForm &&
                <Form data={form} msgContent={msgContent} />
            }
            <StyledFooter>
                <div className='footer'>
                    <div className='logo-social' id='logo-social'>
                        <div className="logo">
                            <Link to="/">
                                <img src={logo} alt="Ponto Urbano" />
                            </Link>
                        </div>
                        <div className='social'>
                            <span>{language === 'pt' ? 'Siga-nos em: ' : 'Follow Us: '}</span>
                            <div className='social-icons'>
                                <a href="https://www.facebook.com/pontourbanoportugal" target='_blank' rel="noreferrer">
                                    <img src={face} alt="Facebook" />
                                </a>
                                <a href="https://www.instagram.com/pontourbanoportugal/" target='_blank' rel="noreferrer">
                                    <img src={insta} alt="Instagram" />
                                </a>
                                <a href="https://www.youtube.com/channel/UCYSiA12IkpSrKKiuUm-tCOA" target='_blank' rel="noreferrer">
                                    <img src={tube} alt="Youtube" />
                                </a>
                                <a href="https://www.linkedin.com/company/pontourbano-consultores" target='_blank' rel="noreferrer">
                                    <img src={linked} alt="LinkedIn" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className='pt2020' id='pt2020'>
                        <div style={{ alignSelf: "flex-start", height: "10vh" }}>
                            <Link to="/imoveis-retoma/quinta-do-sobreiro">
                                <div className={en ? 'imoveis-retoma-en' : 'imoveis-retoma'}></div>
                            </Link>
                        </div>
                        <div style={{ alignSelf: "flex-end" }}>
                            <a href="/Ponto_Urbano-Ficha_projeto.pdf" target="_blank" rel="nofollow">
                                <Image
                                    src={'pt2020w.png'}
                                    alt="PT2020"
                                    className="pt2020" />
                            </a>
                        </div>
                    </div>
                    <div className="contacts" id='contacts'>
                        <div className='address'>
                            <p>
                                Rua da Paz nº 155<br />
                                3800-587 Cacia,<br />
                                Aveiro
                            </p>
                        </div>
                        <div className='email-number'>
                            <a href="mailto:geral@pontourbano.pt">
                                <p>geral@pontourbano.pt</p>
                            </a>
                            <a onClick={() => handleCall('tel:+351234249468', 'Geral')} target="_self" role="button" tabIndex={0} href={() => handleCall('tel:+351234249468', 'Geral')}>
                                <p>+351 234 249 468</p>
                                <small>{msgContent.fix}</small>
                            </a>
                        </div>
                    </div>

                    <div className="livro-reclamacoes" id='livro-reclamacoes'>
                        {language === 'en' ? (
                            <div style={{ textAlign: "right" }}>
                                <a style={{ textAlign: "right" }} href="/en/terms_and_conditions_en.pdf" target="_blank" rel="noreferrer noopener">
                                    Terms and Conditions
                                </a>
                                <a style={{ textAlign: "right" }} href="/en/privacy_policy_en.pdf" target="_blank" rel="noreferrer noopener">
                                    Privacy Policy
                                </a>
                                <a style={{ textAlign: "right", marginBottom: "5%" }} href="/en/cookie_policy_en.pdf" target="_blank" rel="noreferrer noopener">
                                    Cookie Policy
                                </a>
                            </div>

                        ) : (
                            <>
                                <a style={{ textAlign: "right" }} href="/pt/termos_e_condicoes_pt.pdf" target="_blank" rel="noreferrer noopener">
                                    Termos e Condições
                                </a>
                                <a style={{ textAlign: "right" }} href="/pt/politica_de_privacidade_pt.pdf" target="_blank" rel="noreferrer noopener">
                                    Política de Privacidade
                                </a>
                                <a style={{ textAlign: "right", marginBottom: "5%" }} href="/pt/politica_de_cookies_pt.pdf" target="_blank" rel="noreferrer noopener">
                                    Política de Cookies
                                </a>
                            </>

                        )}
                        <a href="https://livroreclamacoes.pt/inicio" target="_blank" rel="noreferrer noopener">
                            <Image src='icon_livro_reclamacoes_cinzapu.png' alt='Livro de Reclamações' />
                        </a>
                        <a href="https://invisual.pt" target='_blank' rel="noreferrer">
                            <p className="created">Created: Invisual.pt</p>
                        </a>
                    </div>
                </div>
            </StyledFooter>
        </>
    )
}

export default Footer

const StyledFooter = styled.footer`
    background-color: #030303;
    color: #c1c5c8;
    padding: clamp(50px, 10vw, 90px) 7.3%;
    text-transform:uppercase;
    font-size:0.8rem;
    letter-spacing:0.1rem;
    line-height:1.3rem;
    z-index: 99;
    position: relative;

    a{
        cursor:pointer;
    }

    .created{
        text-align: right;
        margin-top: 1vh;
    }

    .imoveis-retoma{
        margin-bottom: 2vh;
        border: 1px solid white;
        text-align: center;
        background-image: url("https://pontourbano.pt/images/imoveisRetoma/logo_branco.png");
        height: 6vh;
        width: auto;
        background-repeat: no-repeat;
        background-size: contain;
        -o-background-size: contain;
        -moz-background-size: contain;
        -webkit-background-size: contain;
        background-position: center;
        padding-top: 1vh;
        background-origin: content-box;
        padding-bottom: 1vh;
        max-width: 15vh;
        margin-right: auto;
        padding: 0.5vh;
        display:none;

        @media(max-width:990px){
            margin-left: 0;
            margin-right: auto;
        }
        :hover{
            background-image: url("https://pontourbano.pt/images/imoveisRetoma/logo_preto.png") ;
            background-color: white;
        }
    }
    .imoveis-retoma-en{
        margin-bottom: 2vh;
        border: 1px solid white;
        text-align: center;
        background-image: url("https://pontourbano.pt/images/imoveisRetoma/logo_branco_en.png");
        height: 6vh;
        width: auto;
        background-repeat: no-repeat;
        background-size: contain;
        -o-background-size: contain;
        -moz-background-size: contain;
        -webkit-background-size: contain;
        background-position: center;
        padding-top: 1vh;
        background-origin: content-box;
        padding-bottom: 1vh;
        max-width: 15vh;
        margin-right: auto;
        padding: 0.5vh;
        display:none;

        @media(max-width:990px){
            margin-left: 0;
            margin-right: auto;
        }
        :hover{
            background-image: url("https://pontourbano.pt/images/imoveisRetoma/logo_preto_en.png") ;
            background-color: white;
        }
    }
    .img-imoveis-retoma{
        max-width: 12vh;
        
    }

    .footer{
        display: flex;
        justify-content: space-between;
        align-items:flex-start;
        gap: 3%;

        ${media.xxl`
            #logo-social{
                grid-area:logo
            }
            #pt2020{
                grid-area:pt2020
            }
            #contacts{
                grid-area:contacts
            }
            #livro-reclamacoes{
                grid-area:livro
            }

            display:grid;
            grid-template-areas:'logo contacts''pt2020 livro';
            grid-template-columns:repeat(2,1fr);
            gap: 3rem 3%;
        `}

        ${media.m`
            grid-template-areas:'logo' 'contacts''pt2020' 'livro';
            grid-template-columns:1fr;
        `}

        .logo-social{
            .logo{
                img{
                    max-width:300px;
                    width:100%;
                }
            }

            .social{
                margin-top:1.5rem;

                .social-icons{
                    display: flex;
                    gap: 1rem;
                    align-items: center;
                    margin-top:1rem;

                    a{
                        display:flex;

                        img{
                            width:20px;
                            height:20px;
                        }
                    }
                }     
            }
        }

        .pt2020{
            max-width:280px;
            width:100%;

            ${media.s`
                max-width:250px;
            `}
            img{
                max-width:280px;
                width:100%;

                ${media.s`
                    max-width:250px;
                `}
            }
        }

        .livro-reclamacoes{
            .image{
                width:110px;
                margin-left:auto;

                ${media.m`
                    margin-left:unset;
                `}

                ${media.s`
                    max-width:125px;
                `}
            }
        }

        .contacts{
            text-align:right;

            ${media.m`
                text-align:left;
            `}

            .email-number{
                margin-top:1rem;

                a{
                    margin-left:auto;

                    ${media.m`
                        margin-left:unset;
                    `}
                }
            }
        }
    }  
    
    .bottom-footer{
        padding-top: clamp(20px, 5vw, 30px);
        display: flex;
        justify-content: flex-end;

        ${media.m`
            justify-content: flex-start;
        `}
    }
`