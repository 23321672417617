import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { CSSTransition } from 'react-transition-group'
import { toggleNewsletter } from "../state/app"
import { connect } from 'react-redux'
import { useI18next } from 'gatsby-plugin-react-i18next'

import contactIcon from '../images/nav/contact.svg'
import newsletterIcon from '../images/nav/newsletter.svg'

const Popup = ({ data, openNewsletter, whiteSidebar, dispatch }) => {
    const { language } = useI18next()

    const [hoverContactInfo, setHoverContactInfo] = useState(false)
    const [hoverNewsletterInfo, setHoverNewsletterInfo] = useState(false)

   
    return (
        <StyledPopup hoverContact={hoverContactInfo} hoverNewsletter={hoverNewsletterInfo} whiteSidebar={whiteSidebar}>
            <a href='#form-footer'
                onMouseEnter={() => setHoverContactInfo(true)}
                onMouseLeave={() => setHoverContactInfo(false)}
            >
                <CSSTransition in={hoverContactInfo} timeout={250} classNames="animation" unmountOnExit>
                    <span>{language === 'pt' ? 'Fale connosco' : 'Talk to us'}</span>
                </CSSTransition>
                <img src={contactIcon} alt='Contact' className='img-contact' />
            </a>
            <button
                onClick={() => dispatch(toggleNewsletter(!openNewsletter))}
                onMouseEnter={() => setHoverNewsletterInfo(true)}
                onMouseLeave={() => setHoverNewsletterInfo(false)}>
                <CSSTransition in={hoverNewsletterInfo} timeout={250} classNames="animation" unmountOnExit>
                    <span>{language === 'pt' ? 'Subscreva a nossa Newsletter' : 'Subscribe our Newsletter'}</span>
                </CSSTransition>
                <img src={newsletterIcon} alt='Newsletter' className='img-newsletter' />
            </button>
        </StyledPopup>
    )
}

export default connect(state => ({
    openNewsletter: state.app.openNewsletter,
    news: state.app.news,
    whiteSidebar: state.app.whiteSidebar
}), null)(Popup)

const StyledPopup = styled.div`
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items:flex-end;
    gap: 1rem;
    right: 1rem;
    bottom: 15vh;
    z-index: 61;

    img{
        filter: ${props => props.whiteSidebar ? 'invert(0)' : 'invert(1)'};
    }

    a, button{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        color:#ebebeb;
        text-transform:uppercase;
        font-size: 0.8rem;
    }

    span{
        font-weight:400;
        padding: 0 1.5rem;
    }

    ${props =>
        props.hoverNewsletter ? `
            button{
                background-color: #000000B3;
            }

            .img-newsletter{
                filter:invert(0);
            }
        ` : props.hoverContact && `
            a{
                background-color: #000000B3;
            }

            .img-contact{
                filter:invert(0);
            }
        `
    }

    // ANIMAÇÕES
    .animation-enter {
        opacity: 0;
    }
    .animation-enter-active {
        opacity: 1;
        transition: opacity 250ms;
    }
    .animation-exit {
        opacity: 1;
    }
    .animation-exit-active {
        opacity: 0;
        transition: opacity 250ms;
    }
`
